import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import {
  selectLoginAlertToDisplay,
  selectLoginFailed,
  selectLoginFormState,
} from '../+state/login-form/login-form.selectors';
import {
  selectRecoveryAlertToDisplay,
  selectRecoveryFormState,
  selectRecoverySubmissionInProgress,
} from '../+state/recovery-form/recovery-form.selectors';
import {
  selectRecoveryRequestAlertToDisplay,
  selectRecoveryRequestFormState,
  selectRecoveryRequestSubmissionTimestamp,
} from '../+state/recovery-request-form/recovery-request-form.selectors';
import { AccountPersistenceService } from './account.persistence.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  constructor(private store: Store, protected persistenceService: AccountPersistenceService) {}

  getRecoveryFormState() {
    return this.store.pipe(select(selectRecoveryFormState));
  }

  getRecoveryFormSubmissionInProgress() {
    return this.store.pipe(select(selectRecoverySubmissionInProgress));
  }

  getRecoveryFormAlert() {
    return this.store.pipe(select(selectRecoveryAlertToDisplay));
  }

  getRecoveryRequestFormSubmissionTimestamp() {
    return this.store.pipe(select(selectRecoveryRequestSubmissionTimestamp));
  }

  getRecoveryRequestFormState() {
    return this.store.pipe(select(selectRecoveryRequestFormState));
  }

  getRecoveryRequestFormAlert() {
    return this.store.pipe(select(selectRecoveryRequestAlertToDisplay));
  }

  getLoginFormLoginFailed() {
    return this.store.pipe(select(selectLoginFailed));
  }

  getLoginFormState() {
    return this.store.pipe(select(selectLoginFormState));
  }
  getLoginAlertState() {
    return this.store.pipe(select(selectLoginAlertToDisplay));
  }
}
