import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { reducers } from './+state';
import { HealthCheckEffects } from './+state/health-check.effects';

@NgModule({
  imports: [
    StoreModule.forFeature('healthCheck', reducers),
    EffectsModule.forFeature([HealthCheckEffects]),
  ],
})
export class HealthCheckModule {}
