import {
  alertStateAdapter,
  signalableFactory as actionableFactory,
} from './recovery-request-form.adapter';

const { ResetErrorSignal } = alertStateAdapter.getSignals();

export class InitializeRecoveryRequestFormSignal extends actionableFactory.create<
  'Initialize Recovery Request Form',
  Record<string, never>
>('Initialize Recovery Request Form') {}

export class SubmitRecoveryRequestFormSignal extends actionableFactory.create<
  'Submit Recovery Request Form',
  Record<string, never>
>('Submit Recovery Request Form') {}

export class ResetRecoveryRequestAlertSignal extends ResetErrorSignal {}
