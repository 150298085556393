import {
  alertStateAdapter,
  messageableFactory as actionableFactory,
} from './password-change-form.adapter';

// initialization of mixin factory
export const {
  ResetErrorMessage: ResetErrorAdapterMessage,
  AlertErrorMessage: AlertErrorAdapterMessage,
} = alertStateAdapter.getMessages();
export class ResetErrorMessage extends ResetErrorAdapterMessage {}
export class AlertErrorMessage extends AlertErrorAdapterMessage {}

export class InitializePasswordChangeFormMessage extends actionableFactory.create<
  'Initialize Password Change Form',
  {}
>('Initialize Password Change Form') {}

export type PasswordChangeFormMessages =
  | InitializePasswordChangeFormMessage
  | ResetErrorMessage
  | AlertErrorMessage;
