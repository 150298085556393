import { createSelector } from '@ngrx/store';
import { cloneDeep, get } from 'lodash-es';
import { FormGroupState } from 'ngrx-forms';

import { IRecoveryEntity } from '../../interfaces';
import { selectAccountState } from '../account.selectors';
import { State } from '../interfaces';
import { IRecoveryFormState } from '../interfaces/recovery-form-state';
import { alertStateAdapter } from './recovery-form.adapter';

export const getFormState = (state: State) => state.recoveryFormState;
const selectors = alertStateAdapter.getSelectors('alertState');

export const getEntityStateFromFormState = (
  formState: FormGroupState<IRecoveryFormState>
): IRecoveryEntity => {
  const { newPassword1, newPassword2 } = cloneDeep(formState.value);

  const recoveryForm: IRecoveryEntity = {
    newPassword1,
    newPassword2,
    uid: formState.userDefinedProperties.uid,
    token: formState.userDefinedProperties.token,
  };

  return recoveryForm;
};

export const selectRecoveryFormState = createSelector(selectAccountState, getFormState);

export const selectRecoverySubmissionInProgress = createSelector(
  selectRecoveryFormState,
  (formState) => get(formState, ['userDefinedProperties', 'submissionInProgress'])
);

export const selectRecoveryAlertToDisplay = createSelector(
  selectRecoveryFormState,
  selectors.selectAlertState
);
