import { HealthCheckMessages, SetHealthCheckMessage } from './health-check.messages';
import { IHealthCheckState } from './interfaces/health-check-state';

export type State = IHealthCheckState;

export const INITIAL_STATE: IHealthCheckState = {
  displayName: '',
  serverTime: null,
  serverType: '',
  serverName: '',
  stage: '',
  apiVersion: null,
  nextShutDown: null,
};

export function reducer(state: State = INITIAL_STATE, action: HealthCheckMessages) {
  switch (action.type) {
    case SetHealthCheckMessage.TYPE: {
      const healthCheck = action.payload.healthCheck;
      const stage = healthCheck.stage.toLowerCase() === 'prod' ? '' : healthCheck.stage;
      return { ...INITIAL_STATE, ...action.payload.healthCheck, stage };
    }
  }
  return state;
}
