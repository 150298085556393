import { createSelector } from '@ngrx/store';
import { get } from 'lodash-es';
import { FormGroupState } from 'ngrx-forms';

import { ILoginEntity } from '../../interfaces';
import { selectAccountState } from '../account.selectors';
import { State } from '../interfaces';
import { ILoginFormState } from '../interfaces/login-form-state';
import { alertStateAdapter } from './login-form.adapter';

export const getFormState = (state: State) => state.loginFormState;
const selectors = alertStateAdapter.getSelectors('alertState');

export const getEntityStateFromFormState = (
  formState: FormGroupState<ILoginFormState>
): ILoginEntity => formState.value;

export const selectLoginFormState = createSelector(selectAccountState, getFormState);

export const selectLoginAlertToDisplay = createSelector(
  selectLoginFormState,
  selectors.selectAlertState
);

export const selectLoginFailed = createSelector(selectLoginFormState, (formState) =>
  get(formState, ['userDefinedProperties', 'loginFailed'])
);
