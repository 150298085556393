import { createSelector } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { FormGroupState } from 'ngrx-forms';

import { IPasswordChangeEntity } from '../../interfaces';
import { selectAccountState } from '../account.selectors';
import { State } from '../interfaces';
import { IPasswordChangeFormState } from '../interfaces/password-change-form-state';
import { alertStateAdapter } from './password-change-form.adapter';

export const getPasswordChangeFormState = (state: State) => state.passwordChangeFormState;
const selectors = alertStateAdapter.getSelectors('alertState');

export const getEntityStateFromFormState = (
  formState: FormGroupState<IPasswordChangeFormState>
): IPasswordChangeEntity => {
  const { oldPassword, newPassword1, newPassword2 } = cloneDeep(formState.value);

  const hospitalOfficerForm: IPasswordChangeEntity = {
    oldPassword,
    newPassword1,
    newPassword2,
  };

  return hospitalOfficerForm;
};

export const selectPasswordChangeFormState = createSelector(
  selectAccountState,
  getPasswordChangeFormState
);

export const selectPasswordChangeAlertToDisplay = createSelector(
  selectPasswordChangeFormState,
  selectors.selectAlertState
);
