import { FormGroupState } from 'ngrx-forms';
import { IPasswordChangeFormState } from './password-change-form.reducer';

export const oldPasswordEmpty = (state: FormGroupState<IPasswordChangeFormState>) => () => {
  if (state.controls.oldPassword.value === null || state.controls.oldPassword.value === '') {
    return { oldPasswordEmpty: true };
  }
  return null;
};

export const newPasswordLength = (state: FormGroupState<IPasswordChangeFormState>) => () => {
  if (state.controls.newPassword1.value === null || state.controls.newPassword1.value === '') {
    return { newPasswordEmpty: true };
  } else if (state.controls.newPassword1.value.length < 8) {
    return { newPasswordLength: true };
  }

  return null;
};

export const passwordsNotMatch = (state: FormGroupState<IPasswordChangeFormState>) => () =>
  state.controls.newPassword1.value !== state.controls.newPassword2.value
    ? { passwordsNotMatch: true }
    : null;
