import { ActionReducerMap } from '@ngrx/store';
import { IAccountFeatureState } from './interfaces';
import * as fromForm from './password-change-form/password-change-form.reducer';
import * as fromLogin from './login-form/login-form.reducer';
import * as fromRecovery from './recovery-form/recovery-form.reducer';
import * as fromRecoveryRequest from './recovery-request-form/recovery-request-form.reducer';

export const reducers: ActionReducerMap<IAccountFeatureState> = {
  passwordChangeFormState: fromForm.reducer,
  loginFormState: fromLogin.reducer,
  recoveryFormState: fromRecovery.reducer,
  recoveryRequestFormState: fromRecoveryRequest.reducer,
};
