import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MarkAsDirtyAction, SetUserDefinedPropertyAction, SetValueAction } from 'ngrx-forms';
import { catchError, concat, filter, map, merge, mergeMap, of } from 'rxjs';

import { AuthService } from '@locumsnest/auth/src/lib/session-auth/services';
import { IQueryParams } from '@locumsnest/core/src';
import { isBadRequestError } from '@locumsnest/core/src/lib/helpers/error';

import { InitializePasswordChangeFormMessage } from '../password-change-form';
import { AlertErrorMessage, conditionalErrorHandler } from './login-form.adapter';
import { ClearPasswordFieldMessage, InitializeLoginFormMessage } from './login-form.messages';
import { FORM_ID } from './login-form.reducer';
import * as formSelectors from './login-form.selectors';
import { InitializeLoginFormSignal, SubmitLoginFormSignal } from './login-form.signals';

@Injectable()
export class LoginFormEffects {
  initializeLoginFormSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InitializeLoginFormSignal>(InitializeLoginFormSignal.TYPE),
      mergeMap(() =>
        merge(this.initializeLoginForm(), of(new InitializePasswordChangeFormMessage({}))),
      ),
    ),
  );

  submitLoginFormSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubmitLoginFormSignal.TYPE),
      concatLatestFrom(() => this.store.pipe(select(formSelectors.selectLoginFormState))),
      mergeMap(([_, formState]) => {
        if (formState.isInvalid) {
          return of(new MarkAsDirtyAction(FORM_ID));
        }

        const { username, password } = formSelectors.getEntityStateFromFormState(formState);
        const {
          userDefinedProperties: { token },
        } = formState;
        const loginParams: IQueryParams = token
          ? { username, password, recaptcha: token }
          : { username, password };

        return concat(
          of(new SetUserDefinedPropertyAction(FORM_ID, 'loginFailed', false)),
          this.authService.login(loginParams),
        ).pipe(
          catchError((error: any) => {
            const messages: Actions[] = [];

            if (isBadRequestError(error)) {
              if (error.error.recaptcha) {
                messages.push(this.setRecaptchaToken());
                messages.push(of(new SetUserDefinedPropertyAction(FORM_ID, 'loginFailed', false)));
              } else {
                messages.push(of(new ClearPasswordFieldMessage({})));
                messages.push(of(new SetUserDefinedPropertyAction(FORM_ID, 'loginFailed', true)));
              }
            }

            return merge(
              ...messages,
              conditionalErrorHandler({
                errorEventMessageHandler: (message) =>
                  `An error occurred while logging in: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Login Failed. The error was: ${message}`,
                unknownErrorMessage: 'Sorry! Login Failed. Please try again in a few minutes',
                badRequestMessageHandlerMap: {
                  recaptcha: () => 'Error verifying reCAPTCHA, please try again.',
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  non_field_errors: () => 'Incorrect email and/or password',
                },
              })(error),
            );
          }),
        );
      }),
    ),
  );

  resetMessageSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetValueAction<string>>(SetValueAction.TYPE),
      filter((action) => action.controlId.startsWith(FORM_ID)),
      map(() => new AlertErrorMessage({ message: '', type: '' })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private recaptchaV3Service: ReCaptchaV3Service,
    private authService: AuthService,
  ) {}

  private initializeLoginForm() {
    return this.recaptchaV3Service
      .execute('login')
      .pipe(mergeMap((token) => of(new InitializeLoginFormMessage({ token }))));
  }

  private setRecaptchaToken() {
    return this.recaptchaV3Service
      .execute('login')
      .pipe(mergeMap((token) => of(new SetUserDefinedPropertyAction(FORM_ID, 'token', token))));
  }
}
