import {
  createFormGroupState,
  createFormStateReducerWithUpdate,
  FormGroupState,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { Exception } from 'sass';

import { IAlertState } from '@locumsnest/core/src/lib/adapters/alert-state-adapter/interfaces';
import { required } from '@locumsnest/core/src/lib/ngrx/helpers/ngrx-forms/error-messages.helper';

import { IPasswordChangeFormState } from '../interfaces/password-change-form-state';
import { alertStateAdapter } from './password-change-form.adapter';
import {
  InitializePasswordChangeFormMessage,
  PasswordChangeFormMessages,
} from './password-change-form.messages';
import {
  newPasswordLength,
  oldPasswordEmpty,
  passwordsNotMatch,
} from './password-change-form.validators';

export * from './password-change-form.selectors';
export const FORM_ID = 'PASSWORD_CHANGE_FORM';
export type State = FormGroupState<IPasswordChangeFormState> & { alertState?: IAlertState };

export const INITIAL_FORM_STATE = createFormGroupState<IPasswordChangeFormState>(FORM_ID, {
  oldPassword: '',
  newPassword1: '',
  newPassword2: '',
  passwordSuccessFullyUpdated: false,
});

const formValidationReducer = (formState: FormGroupState<IPasswordChangeFormState>) =>
  updateGroup<IPasswordChangeFormState>({
    oldPassword: (oldPassword, state) => validate<string>([oldPasswordEmpty(state)])(oldPassword),
    newPassword1: (newPassword1, state) =>
      validate<string>([newPasswordLength(state)])(newPassword1),
    newPassword2: (newPassword2, state) =>
      validate<string>([passwordsNotMatch(state)])(newPassword2),
  })(formState);

export const formStateReducer = createFormStateReducerWithUpdate<IPasswordChangeFormState>([]);

const alertStateReducer = alertStateAdapter.createReducer({});

export function formReducer(
  state: FormGroupState<IPasswordChangeFormState> = INITIAL_FORM_STATE,
  action: PasswordChangeFormMessages
) {
  let newState = state;

  switch (action.type) {
    case InitializePasswordChangeFormMessage.TYPE:
      newState = createFormGroupState<IPasswordChangeFormState>(FORM_ID, INITIAL_FORM_STATE.value);
      break;
  }

  newState = formStateReducer(newState, action);

  if (newState !== state) {
    newState = formValidationReducer(newState);
  }

  return newState;
}

export function reducer(state: State, action) {
  const form = formReducer(state, action);
  const alertState = alertStateReducer(state ? state.alertState : undefined, action);
  return {
    ...form,
    alertState,
  };
}

export { IPasswordChangeFormState } from '../interfaces/password-change-form-state';
