import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { SetUserDefinedPropertyAction } from 'ngrx-forms';
import { concat, from, merge, Observable, of, timer } from 'rxjs';
import { catchError, debounceTime, mergeMap } from 'rxjs/operators';

import { IQueryParams } from '@locumsnest/core/src';

import { AccountPersistenceService } from '../../services';
import { IRouterService } from './../../interfaces/router';
import { ACCOUNT_ROUTER_SERVICE } from './../../opaque-tokens';
import {
  alertHandler,
  alertSignalToMessage,
  conditionalErrorHandler,
} from './recovery-form.adapter';
import { InitializeRecoveryFormMessage } from './recovery-form.messages';
import { FORM_ID } from './recovery-form.reducer';
import * as FormSelectors from './recovery-form.selectors';
import { SubmitRecoveryFormSignal } from './recovery-form.signals';

@Injectable()
export class RecoveryFormEffects {
  alertSignal$ = createEffect(() => this.actions$.pipe(alertSignalToMessage));

  submitRecoveryFormSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SubmitRecoveryFormSignal>(SubmitRecoveryFormSignal.TYPE),
      debounceTime(2000),
      concatLatestFrom(() => [
        this.store.pipe(select(FormSelectors.selectRecoveryFormState)),
        this.routerService.getQueryParam('uid', true),
        this.routerService.getQueryParam('token', true),
        this.routerService.getQueryParam('next', true),
      ]),
      mergeMap(([_, formState, uid, token, next]) => {
        const recoveryFormState = FormSelectors.getEntityStateFromFormState(formState);

        const recoveryParams: IQueryParams = {
          newPassword1: recoveryFormState.newPassword1,
          newPassword2: recoveryFormState.newPassword2,
          uid,
          token,
        };

        return concat(
          of(new SetUserDefinedPropertyAction(FORM_ID, 'submissionInProgress', true)),
          this.accountPersistenceService.confirmRecovery(recoveryParams).pipe(
            mergeMap(() =>
              merge(
                of(
                  new InitializeRecoveryFormMessage({
                    recoveryFormState: {
                      newPassword1: null,
                      newPassword2: null,
                    },
                  })
                ),
                alertHandler({
                  message: "Password was set successfully! You 'll be redirected to login shortly.",
                  type: 'info',
                }),
                timer(3000).pipe(
                  mergeMap(() => {
                    const queryParams = next?.includes('onboarding') ? { onboarding: true } : {};
                    this.router.navigate(['/login'], { queryParams });
                    return from([]) as Observable<Action>;
                  })
                )
              )
            ),
            catchError(
              conditionalErrorHandler({
                errorEventMessageHandler: (message) =>
                  `An error occurred while changing the password: ${message}`,
                errorDetailMessageHandler: (message) =>
                  `Sorry! Password can’t be changed. The error was: ${message}`,
                unknownErrorMessage:
                  'Sorry! Password can’t be changed. Please try again in a few minutes',
                badRequestMessageHandlerMap: {
                  token: () => 'Please request a new password reset. (No valid token provided)',
                  uid: () => 'No valid uid provided',
                },
              })
            )
          ),
          of(new SetUserDefinedPropertyAction(FORM_ID, 'submissionInProgress', false))
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private accountPersistenceService: AccountPersistenceService,
    @Inject(ACCOUNT_ROUTER_SERVICE) private routerService: IRouterService,
    private router: Router
  ) {}
}
