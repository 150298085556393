import {
  signalableFactory as actionableFactory,
  alertStateAdapter,
} from './password-change-form.adapter';
const { ResetErrorSignal } = alertStateAdapter.getSignals();

export class InitializePasswordChangeFormSignal extends actionableFactory.create<
  'Initialize Password Change Form',
  Record<string, never>
>('Initialize Password Change Form') {}

export class SubmitPasswordChangeFormSignal extends actionableFactory.create<
  'Submit Password Change Form',
  void
>('Submit Password Change Form') {}

export class ResetAlertSignal extends ResetErrorSignal {}
