import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { merge, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { IQueryParams } from '@locumsnest/core/src';

import { IPasswordChangeEntity } from '../../interfaces';
import { AccountPersistenceService } from '../../services';
import {
  alertHandler,
  alertSignalToMessage,
  conditionalErrorHandler,
} from './password-change-form.adapter';
import { InitializePasswordChangeFormMessage } from './password-change-form.messages';
import * as FormSelectors from './password-change-form.selectors';
import { SubmitPasswordChangeFormSignal } from './password-change-form.signals';

@Injectable()
export class PasswordChangeFormEffects {
  submitPasswordChangeFormSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SubmitPasswordChangeFormSignal>(SubmitPasswordChangeFormSignal.TYPE),
      concatLatestFrom(() => this.store.pipe(select(FormSelectors.selectPasswordChangeFormState))),
      mergeMap(([_, formState]) => {
        const passwordChangeFormState = FormSelectors.getEntityStateFromFormState(formState);

        const passwordChangeParams: IQueryParams = {
          oldPassword: passwordChangeFormState.oldPassword,
          newPassword1: passwordChangeFormState.newPassword1,
          newPassword2: passwordChangeFormState.newPassword2,
        };

        return this.accountPersistenceService.passwordChange(passwordChangeParams).pipe(
          mergeMap((response: IPasswordChangeEntity) =>
            merge(
              of(
                new InitializePasswordChangeFormMessage({
                  passwordChangeFormState: {
                    oldPassword: null,
                    newPassword1: null,
                    newPassword2: null,
                    passwordSuccessFullyUpdated: true,
                  },
                })
              ),
              alertHandler(
                {
                  message: 'Password changed successfully!',
                  type: 'info',
                },
                this.actions$
              )
            )
          ),
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while changing the password: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Password can’t be changed. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Password can’t be changed. Please try again in a few minutes',
              badRequestMessageHandlerMap: {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                old_password: (k, v) => 'The old password is invalid',
              },
            })
          )
        );
      })
    )
  );

  alertSignal$ = createEffect(() => this.actions$.pipe(alertSignalToMessage));

  constructor(
    private actions$: Actions,
    private store: Store,
    private accountPersistenceService: AccountPersistenceService
  ) {}
}
