import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { SetUserDefinedPropertyAction } from 'ngrx-forms';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { IQueryParams } from '@locumsnest/core/src';

import { IRecoveryRequestEntity } from '../../interfaces';
import { AccountPersistenceService } from '../../services';
import { FORM_ID } from '../recovery-request-form/recovery-request-form.reducer';
import { conditionalErrorHandler } from './recovery-request-form.adapter';
import { InitializeRecoveryRequestFormMessage } from './recovery-request-form.messages';
import * as FormSelectors from './recovery-request-form.selectors';
import {
  InitializeRecoveryRequestFormSignal,
  SubmitRecoveryRequestFormSignal,
} from './recovery-request-form.signals';

@Injectable()
export class RecoveryRequestFormEffects {
  initializeRecoveryRequestFormSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<InitializeRecoveryRequestFormSignal>(InitializeRecoveryRequestFormSignal.TYPE),
      mergeMap(() => of(new InitializeRecoveryRequestFormMessage({})))
    )
  );

  submitRecoveryRequestFormSignal$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SubmitRecoveryRequestFormSignal>(SubmitRecoveryRequestFormSignal.TYPE),
      concatLatestFrom(() => this.store.pipe(select(FormSelectors.selectRecoveryRequestFormState))),
      mergeMap(([_, formState]) => {
        const recoveryRequestFormState = FormSelectors.getEntityStateFromFormState(formState);

        const recoveryRequestParams: IQueryParams = {
          email: recoveryRequestFormState.email,
        };

        return this.accountPersistenceService.recoveryRequest(recoveryRequestParams).pipe(
          mergeMap((response: IRecoveryRequestEntity) =>
            of(new SetUserDefinedPropertyAction(FORM_ID, 'submissionTimestamp', Date.now()))
          ),
          catchError(
            conditionalErrorHandler({
              errorEventMessageHandler: (message) =>
                `An error occurred while recovering the password: ${message}`,
              errorDetailMessageHandler: (message) =>
                `Sorry! Password can’t be recovered. The error was: ${message}`,
              unknownErrorMessage:
                'Sorry! Password can’t be recovered. Please try again in a few minutes',
            })
          )
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private accountPersistenceService: AccountPersistenceService
  ) {}
}
