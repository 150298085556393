import { IAlertState } from '@locumsnest/core/src/lib/adapters/alert-state-adapter/interfaces';
import {
  createFormStateReducerWithUpdate,
  createFormGroupState,
  FormGroupState,
  updateGroup,
  validate,
} from 'ngrx-forms';
import {
  RecoveryRequestFormMessages,
  InitializeRecoveryRequestFormMessage,
} from './recovery-request-form.messages';
import { alertStateAdapter } from './recovery-request-form.adapter';
import { IRecoveryRequestFormState } from '../interfaces/recovery-request-form-state';
import { required, pattern } from 'ngrx-forms/validation';
import { EMAIL_REGEX } from '@locumsnest/core/src/lib/types/constants';

export * from './recovery-request-form.selectors';
export const FORM_ID = 'RECOVERY_REQUEST_FORM';
export type State = FormGroupState<IRecoveryRequestFormState> & { alertState?: IAlertState };

export const INITIAL_FORM_STATE = createFormGroupState<IRecoveryRequestFormState>(FORM_ID, {
  email: '',
});

const formValidationReducer = (formState: FormGroupState<IRecoveryRequestFormState>) =>
  updateGroup<IRecoveryRequestFormState>({
    email: validate<string>([required, pattern(EMAIL_REGEX)]),
  })(formState);

export const formStateReducer = createFormStateReducerWithUpdate<IRecoveryRequestFormState>([]);

const alertStateReducer = alertStateAdapter.createReducer();

export function formReducer(
  state: FormGroupState<IRecoveryRequestFormState> = INITIAL_FORM_STATE,
  action: RecoveryRequestFormMessages
) {
  switch (action.type) {
    case InitializeRecoveryRequestFormMessage.TYPE:
      state = createFormGroupState<IRecoveryRequestFormState>(FORM_ID, INITIAL_FORM_STATE.value);
      break;
  }

  state = formStateReducer(state, action);
  state = formValidationReducer(state);

  return state;
}

export function reducer(state: State, action) {
  const form = formReducer(state, action);
  const alertState = alertStateReducer(state ? state.alertState : undefined, action);
  return {
    ...form,
    alertState,
  };
}

export { IRecoveryRequestFormState } from '../interfaces/recovery-request-form-state';
