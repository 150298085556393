import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HealthCheckModule } from 'apps/hospital-admin/src/app/health-check/health-check.module';
import { environment } from 'apps/hospital-admin/src/environments/environment';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { reducers } from './+state';
import { AccountEffects } from './+state/account.effects';
import { LoginFormEffects } from './+state/login-form/login-form.effects';
import { PasswordChangeFormEffects } from './+state/password-change-form';
import { RecoveryFormEffects } from './+state/recovery-form';
import { RecoveryRequestFormEffects } from './+state/recovery-request-form';

@NgModule({
  imports: [
    HealthCheckModule,
    StoreModule.forFeature('accounts', reducers),
    EffectsModule.forFeature([
      AccountEffects,
      PasswordChangeFormEffects,
      RecoveryFormEffects,
      LoginFormEffects,
      RecoveryRequestFormEffects,
    ]),
    RecaptchaV3Module,
  ],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaSiteKey,
    },
  ],
})
export class AccountModule {}
