import { signalableFactory as actionableFactory } from './recovery-form.adapter';

export class InitializeRecoveryFormSignal extends actionableFactory.create<
  'Initialize Recovery Form',
  Record<string, never>
>('Initialize Recovery Form') {}

export class SubmitRecoveryFormSignal extends actionableFactory.create<
  'Submit Recovery Form',
  void
>('Submit Recovery Form') {}
