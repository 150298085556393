import { IAlertState } from '@locumsnest/core/src/lib/adapters/alert-state-adapter/interfaces';
import {
  createFormStateReducerWithUpdate,
  createFormGroupState,
  FormGroupState,
  updateGroup,
  validate,
} from 'ngrx-forms';
import { RecoveryFormMessages, InitializeRecoveryFormMessage } from './recovery-form.messages';
import { alertStateAdapter } from './recovery-form.adapter';
import { IRecoveryFormState } from '../interfaces/recovery-form-state';
import { newPasswordLength, passwordsNotMatch } from './recovery-form.validators';

export * from './recovery-form.selectors';
export const FORM_ID = 'RECOVERY_FORM';
export type State = FormGroupState<IRecoveryFormState> & { alertState?: IAlertState };

export const INITIAL_FORM_STATE = createFormGroupState<IRecoveryFormState>(FORM_ID, {
  newPassword1: '',
  newPassword2: '',
});

const formValidationReducer = (formState: FormGroupState<IRecoveryFormState>) =>
  updateGroup<IRecoveryFormState>({
    newPassword1: (newPassword1, state) =>
      validate<string>([newPasswordLength(state)])(newPassword1),
    newPassword2: (newPassword2, state) =>
      validate<string>([passwordsNotMatch(state)])(newPassword2),
  })(formState);

export const formStateReducer = createFormStateReducerWithUpdate<IRecoveryFormState>([]);

const alertStateReducer = alertStateAdapter.createReducer({});

export function formReducer(
  state: FormGroupState<IRecoveryFormState> = INITIAL_FORM_STATE,
  action: RecoveryFormMessages
) {
  switch (action.type) {
    case InitializeRecoveryFormMessage.TYPE:
      state = createFormGroupState<IRecoveryFormState>(
        FORM_ID,
        (action as InitializeRecoveryFormMessage).payload.recoveryFormState
      );
      break;
  }

  state = formStateReducer(state, action);
  state = formValidationReducer(state);

  return state;
}

export function reducer(state: State, action) {
  const form = formReducer(state, action);
  const alertState = alertStateReducer(state ? state.alertState : undefined, action);
  return {
    ...form,
    alertState,
  };
}

export { IRecoveryFormState } from '../interfaces/recovery-form-state';
