import { messageableFactory as actionableFactory, alertStateAdapter } from './login-form.adapter';
import { ILoginFormState } from '../interfaces/login-form-state';

// initialization of mixin factory
export const {
  ResetErrorMessage: ResetErrorAdapterMessage,
  AlertErrorMessage: AlertErrorAdapterMessage,
} = alertStateAdapter.getMessages();
export class ResetErrorMessage extends ResetErrorAdapterMessage {}
export class AlertErrorMessage extends AlertErrorAdapterMessage {}

export class InitializeLoginFormMessage extends actionableFactory.create<
  'Initialize Login Form',
  { token: string }
>('Initialize Login Form') {}

export class ClearPasswordFieldMessage extends actionableFactory.create<
  'Clear Password Field',
  Record<string, never>
>('Clear Password Field') {}

export type LoginFormMessages =
  | InitializeLoginFormMessage
  | ResetErrorMessage
  | AlertErrorMessage
  | ClearPasswordFieldMessage;
