import {
  messageableFactory as actionableFactory,
  alertStateAdapter,
} from './recovery-form.adapter';
import { IRecoveryFormState } from '../interfaces/recovery-form-state';

// initialization of mixin factory
export const {
  ResetErrorMessage: ResetErrorAdapterMessage,
  AlertErrorMessage: AlertErrorAdapterMessage,
} = alertStateAdapter.getMessages();
export class ResetErrorMessage extends ResetErrorAdapterMessage {}
export class AlertErrorMessage extends AlertErrorAdapterMessage {}

export class InitializeRecoveryFormMessage extends actionableFactory.create<
  'Initialize Recovery Form',
  { recoveryFormState: IRecoveryFormState }
>('Initialize Recovery Form') {}

export type RecoveryFormMessages =
  | InitializeRecoveryFormMessage
  | ResetErrorMessage
  | AlertErrorMessage;
