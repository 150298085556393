import { FormGroupState } from 'ngrx-forms';
import { IRecoveryFormState } from './recovery-form.reducer';

export const newPasswordLength = (state: FormGroupState<IRecoveryFormState>) => () => {
  if (state.controls.newPassword1.value === null || state.controls.newPassword1.value === '') {
    return { newPasswordEmpty: true };
  } else if (state.controls.newPassword1.value.length < 8) {
    return { newPasswordLength: true };
  }

  return null;
};

export const passwordsNotMatch = (state: FormGroupState<IRecoveryFormState>) => () =>
  state.controls.newPassword1.value !== state.controls.newPassword2.value
    ? { passwordsNotMatch: true }
    : null;
