import { createSelector } from '@ngrx/store';
import { cloneDeep, get } from 'lodash-es';
import { FormGroupState } from 'ngrx-forms';

import { IRecoveryRequestEntity } from '../../interfaces';
import { selectAccountState } from '../account.selectors';
import { State } from '../interfaces';
import { IRecoveryRequestFormState } from '../interfaces/recovery-request-form-state';
import { alertStateAdapter } from './recovery-request-form.adapter';

export const getFormState = (state: State) => state.recoveryRequestFormState;

const selectors = alertStateAdapter.getSelectors('alertState');

export const getEntityStateFromFormState = (
  formState: FormGroupState<IRecoveryRequestFormState>
): IRecoveryRequestEntity => {
  const { email } = cloneDeep(formState.value);

  const hospitalOfficerForm: IRecoveryRequestEntity = {
    email,
  };

  return hospitalOfficerForm;
};

export const selectRecoveryRequestFormState = createSelector(selectAccountState, getFormState);

export const selectRecoveryRequestSubmissionTimestamp = createSelector(
  selectRecoveryRequestFormState,
  (formState) => get(formState, ['userDefinedProperties', 'submissionTimestamp'])
);

export const selectRecoveryRequestAlertToDisplay = createSelector(
  selectRecoveryRequestFormState,
  selectors.selectAlertState
);
