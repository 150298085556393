import { alertStateAdapter, signalableFactory as actionableFactory } from './login-form.adapter';

const { ResetErrorSignal } = alertStateAdapter.getSignals();

export class InitializeLoginFormSignal extends actionableFactory.create<
  'Initialize Login Form',
  Record<string, never>
>('Initialize Login Form') {}

export class SubmitLoginFormSignal extends actionableFactory.create<
  'Submit Login Form',
  Record<string, never>
>('Submit Login Form') {}

export class ResetLoginAlertSignal extends ResetErrorSignal {}
