import { IAlertState } from '@locumsnest/core/src/lib/adapters/alert-state-adapter/interfaces';
import {
  createFormStateReducerWithUpdate,
  createFormGroupState,
  FormGroupState,
  validate,
  updateGroup,
  setValue,
  markAsUntouched,
  markAsPristine,
  setUserDefinedProperty,
} from 'ngrx-forms';
import {
  LoginFormMessages,
  InitializeLoginFormMessage,
  ClearPasswordFieldMessage,
} from './login-form.messages';
import { alertStateAdapter } from './login-form.adapter';
import { ILoginFormState } from '../interfaces/login-form-state';
import { required } from 'ngrx-forms/validation';

export * from './login-form.selectors';
export const FORM_ID = 'LOGIN_FORM';
export type State = FormGroupState<ILoginFormState> & { alertState?: IAlertState };

export const INITIAL_FORM_STATE = createFormGroupState<ILoginFormState>(FORM_ID, {
  username: '',
  password: '',
});

const formValidationReducer = (formState: FormGroupState<ILoginFormState>) =>
  updateGroup<ILoginFormState>({
    username: validate<string>([required]),
    password: validate<string>([required]),
  })(formState);

export const formStateReducer = createFormStateReducerWithUpdate<ILoginFormState>([]);

const alertStateReducer = alertStateAdapter.createReducer({});

export function formReducer(
  state: FormGroupState<ILoginFormState> = INITIAL_FORM_STATE,
  action: LoginFormMessages
) {
  switch (action.type) {
    case InitializeLoginFormMessage.TYPE:
      const { payload } = action as InitializeLoginFormMessage;

      state = createFormGroupState<ILoginFormState>(FORM_ID, INITIAL_FORM_STATE.value);
      state = setUserDefinedProperty('token', payload.token)(state);
      break;
    case ClearPasswordFieldMessage.TYPE:
      state = updateGroup<ILoginFormState>({
        password: (password) => {
          const newState = setValue(password, null);
          return markAsPristine(newState);
        },
      })(state);
      break;
  }

  state = formStateReducer(state, action);
  state = formValidationReducer(state);

  return state;
}

export function reducer(state: State, action) {
  const form = formReducer(state, action);
  const alertState = alertStateReducer(state ? state.alertState : undefined, action);
  return {
    ...form,
    alertState,
  };
}

export { ILoginFormState } from '../interfaces/login-form-state';
