import {
  messageableFactory as actionableFactory,
  alertStateAdapter,
} from './recovery-request-form.adapter';
import { IRecoveryRequestFormState } from '../interfaces/recovery-request-form-state';

// initialization of mixin factory
export const {
  ResetErrorMessage: ResetErrorAdapterMessage,
  AlertErrorMessage: AlertErrorAdapterMessage,
} = alertStateAdapter.getMessages();
export class ResetErrorMessage extends ResetErrorAdapterMessage {}
export class AlertErrorMessage extends AlertErrorAdapterMessage {}

export class InitializeRecoveryRequestFormMessage extends actionableFactory.create<
  'Initialize Recovery Request Form',
  Record<string, never>
>('Initialize Recovery Request Form') {}

export type RecoveryRequestFormMessages =
  | InitializeRecoveryRequestFormMessage
  | ResetErrorMessage
  | AlertErrorMessage;
